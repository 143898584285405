@import '~admin-lte/build/scss/adminlte.scss';
@import '~toastr/toastr';
@import '~flatpickr/dist/flatpickr.css';
@import "~nice-select2/src/scss/nice-select2.scss";

// Login Page
.login-page {
    height: 100vh !important;
    // background: $gray-100;
    background: #fff;
}

@media only screen and (min-width: 600px) {
    .login-form-wrapper {
      min-width: 500px;
    }
}

$lsblue: #155896;
$lsblue_dark: #0d386c;
$white: #ffffff;

.bg-shoppurple {
  color: $white;
  background-color: $lsblue;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23ffffff' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

.btn-white {
  color: $lsblue;
  background-color: $white;
  border-color: $white;
  box-shadow: none;
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  color: $lsblue_dark;
  background-color: $white;
  border-color: $white;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: $lsblue;
  color: $white;
}

.btn-purple {
  color: #fff;
  background-color: $lsblue;
  border-color: $lsblue;
  box-shadow: none;
}

.btn-purple:hover {
  color: #fff;
  background-color: $lsblue_dark;
  border-color: $lsblue_dark;
}

.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active, .show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: $lsblue_dark;
  border-color: $lsblue_dark;
}

// Links

.accent-shoppurple .page-item.active a, .accent-shoppurple .page-item.active .page-link {
  background-color: $lsblue;
  border-color: $lsblue;
  color: #fff;
}
.accent-shoppurple .page-item .page-link {
  color: $lsblue;
}

.accent-shoppurple .btn-link, .accent-shoppurple a:not(.dropdown-item):not(.btn-app):not(.nav-link):not(.brand-link):not(.page-link):not(.btn), .accent-shoppurple .nav-tabs .nav-link {
  color: $lsblue;
}

.accent-shoppurple .btn-link:hover, .accent-shoppurple a:not(.dropdown-item):not(.btn-app):not(.nav-link):not(.brand-link):not(.page-link):not(.btn):hover, .accent-shoppurple .nav-tabs .nav-link:hover {
  color: $lsblue_dark;
}

// Login Logo

.login-logo {
    width: 100px;
}

// Form Stuff

.required-form-field::after {
  content: ' *';
  color: red;
}

.custom-file-label {
  top: auto;
  bottom: 0;
}

// Date picker
.disbaled-normal {
  background-color: #ffffff !important;
}

.glyphicon-move {
  cursor: move;
  cursor: -webkit-grabbing;
}

.opacity-50 {
  transition: all .3s;
  -webkit-transition: opacity 0.3s;
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}
.alert {
  border-radius: 0;
  
  &-info {
    background-color: #17a3b82d;
    border-color: #148ea1;
    color: #0d386c
  }
}

.nice-select-dropdown {
  width: 100%;
}

.people {
  .person-image {
      border-radius: 50%;
  }
}